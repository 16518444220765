.widgetLayout {
  margin-top: 30px;
  display: inline
}

.widgetLayout .cmStatusBar {
  margin-top: 40px;
  font-weight: 600;
  font-size: 20pt;
}

.widgetLayout .bridgeWidget {
  background-color: rgb(181, 228, 202);
}

.widgetLayout .teamEditingWidget {
  background-color: rgb(177, 229, 252);
}

.widgetLayout .stenoWidget {
  background-color: rgb(202, 189, 255);
}

.widgetLayout .realtimeWidget {
  background-color: rgb(251, 255, 189);
}

.widgetLayout .refreshButton {
  position: absolute;
  right: 10px;
  top: 10px;
}

.widgetLayout .widgetBadge {
  padding: 0px 6px;
  margin: 0px 4px;
  border-radius: 6px;
  color: white;
  display: inline-block;
  font-size: 8pt;
}

.widgetLayout .usersBadge {
  background-color: rgb(55, 194, 62);
}

.widgetLayout .sessionsBadge {
  background-color: rgb(57, 157, 224);
}

.widgetLayout .loadingBadge {
  background-color: rgb(255, 255, 255);
  color: black;
}

.widgetLayout .errorBadge {
  background-color: rgb(255, 0, 0);
}

.widgetLayout .widget {
  border-radius: 10px;
  height: min-content;
  padding: 2px 0px;
}

.widgetLayout .wrapper {
  width: 100%;
  padding: 6px;
}

.widgetLayout .badgeValue {
  font-weight: bold;
  font-size: 14pt;
}

.widgetLayout .expandToggle {
  width: 28px;
  height: 28px; 
}

.widgetLayout .badgeIcon {
  position: relative;
  bottom: -4px;
}

.widget .header {
  font-weight: 600;
}

.widget .content {
  display: inline-block;
  vertical-align: middle;
}

.widget .logoWrapper {
  display: inline-block;
  vertical-align: middle;
  padding: 6px;
}

.widgetLayout .divider {
  display: inline-block;
  padding-left: 10px;
  width: calc(100% - 40px)
}

.widgetLayout button.selectedServerBtn {
  margin-inline: 6px;
  background-color: blue;
  color: white;
  border: solid 1px black;
}
.widgetLayout button.selectedServerBtn:hover {
  margin-inline: 6px;
  background-color: lightblue;
  color: white;
  border: solid 1px black;
}

.widgetLayout button.nonselectedServerBtn {
  margin-inline: 6px;
  background-color: white;
  border: solid 1px gray;
}