.listDialog .MuiDialog-paper {
    width: fit-content;
    min-width: min(400px,100%);
    max-width: min(800px,100%);
    padding: 1rem;
    min-height: 300px;
}

.listDialog .tag {
    background-color: #FFBC99;
    float: left;
    width: 16px;
    height: 32px;
    border-radius: 4px;
    margin-right: 0.5rem;
}

.listDialog .title {
    font-size: 22px;
    font-weight: bold;
}

.listDialog .MuiDivider-root {
	margin-top: 20px;
    margin-bottom: 28px;
}

.listDialog .content {
    align-self: center;
}

.listDialog .closeButton {
    float: right;
}

.listDialog .closeButton .MuiSvgIcon-root {
    background-color: #EFEFEF;
    border-radius: 50%;
}

.listDialog button.itemButton {
    text-transform: none;
    align-items: start;
    justify-content: left;
    color: black;
    border-color: black;
    width: 100%;
}