.csvTable {
  border: solid black 1px;
  text-align: left;
  width: 100%;
}

.csvTable th {
  border: solid black 1px;
  white-space: pre;
}

.csvTable td {
  border: solid black 1px;
  white-space: pre;
}