.usedMinutesReport {
  margin: auto;
  max-width: 1200px;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}

.usedMinutesReport .rsrList {
  display: inline-block;
  vertical-align: top;
  font-weight: bold;
  width: 50%;
}

.usedMinutesReport .rsrList ul li {
  font-weight: normal;
  list-style-type: none;
  border: 1px solid black;
  border-radius: 5px;
}

.usedMinutesReport button.downloadButton {
  text-transform: none;
  background-color: lime;
  color: black;
  width: 20rem;
  max-width: 95%;
  height: 2.5rem;
}