.activityCheckerGraph {
  border: 1px solid lightgray;
  border-radius: 0.5em;
}

.activityCheckerGraph .displayedMessage {
  padding-top: 15px;
  text-align: center;
  font-size: 24pt;
}

.activityCheckerGraph .detectRect {
  fill: none;
  pointer-events: all;
  width: 100%;
  height: 100%;
}

.activityCheckerGraph .selectRect {
  fill: rgba(0, 125, 0, 0.25);
  stroke: rgba(0, 255, 0, 1.0);
  stroke-width: 3px;
  pointer-events: none;
}

.activityCheckerGraph g text {
  pointer-events: none;
  user-select: none;
}

.activityCheckerGraph svg {
  width: 100%;
}

.activityCheckerGraph .horizLine {
  stroke: lightgray;
  stroke-width: 1px;
}

.activityCheckerGraph .vertLine {
  stroke: lightgray;
  stroke-width: 0.5px;
}

.activityCheckerGraph .weekLine {
  stroke: gray;
  stroke-width: 1.0px;
}

.activityCheckerGraph .monthLine {
  stroke: black;
  stroke-width: 1.0px;
}

.activityCheckerGraph .yearLine {
  stroke: black;
  stroke-width: 2.0px;
}

.activityCheckerGraph .dateText {
  stroke: lightgray;
  stroke-width: 0px;
  fill: black;
  font-size: 9pt;
  transform: translate(0.1em, -0.25em)
}

.activityCheckerGraph .activityRect {
  fill: blue;
  ry: 3px;
  stroke: none;
}

.activityCheckerGraph .overlapRectUnder {
  fill: rgba(125, 125, 255, 1);
  pointer-events: none;
}

.activityCheckerGraph .overlapRect {
  fill: rgba(125, 125, 0, 1);
  mix-blend-mode: screen;
  pointer-events: none;
}

.activityCheckerGraph .activityRect:hover {
  stroke: mediumblue;
  stroke-width: 0.2em;
}

.activityCheckerGraph .activityTooltip {
  display: none;
  position: fixed;

  font-size: 9pt;
  font-weight: bold;
  color: white;

  border: 1px solid white;
  border-radius: 6px;
  background-color: black;

  z-index: 2;

  transform: translate(-50%, calc(-20px - 100%));
}

/* Mouseover tooltip */
.activityCheckerGraph .activityTooltip>table {
  table-layout: fixed;
  width: 17.5em;
}

.activityCheckerGraph .activityTooltip tr>td:first-child {
  text-align: right;
  width: 4.5em;
}

.activityCheckerGraph .activityTooltip tr>td:nth-child(2) {
  width: 11.5em;
}

.activityCheckerGraph .activityTooltip.displayedTooltip {
  display: block;
}

.activityCheckerGraph .activityTooltip.displayedTooltip::before {
  z-index: -1;

  content: "";
  position: absolute;
  top: calc(100% - 2px);
  left: 50%;
  width: 20px;
  height: 20px;

  transform: scaleX(0.75) rotate(45deg) translate(-50%);
  background: inherit;
  border-bottom: inherit;
  border-right: inherit;
  box-shadow: inherit;
}