.choiceDialog .MuiDialog-paper {
    width: fit-content;
    min-width: min(400px,100%);
    max-width: min(800px,100%);
    padding: 1rem;
}

.choiceDialog .tag {
    background-color: #FFBC99;
    float: left;
    width: 16px;
    height: 32px;
    border-radius: 4px;
    margin-right: 0.5rem;
}

.choiceDialog .title {
    font-size: 22px;
    font-weight: bold;
}

.choiceDialog .MuiDivider-root {
	margin-top: 20px;
    margin-bottom: 28px;
}

.choiceDialog .content {
    align-self: center;
    margin-bottom: 35px;
}

.choiceDialog .confirmButton {
    border-color: #FF6A55;
    color: #FF6A55;
    min-width: 100px;
}

.choiceDialog .confirmButton:hover {
    border-color: #FF9F8F;
    color: #FF9F8F
}

.choiceDialog .cancelButton {
    background-color: #FF6A55;
    min-width: 100px;
}

.choiceDialog .cancelButton:hover {
    background-color: #FF9F8F;
}

.choiceDialog .closeButton {
    float: right;
}

.choiceDialog .closeButton .MuiSvgIcon-root {
    background-color: #EFEFEF;
    border-radius: 50%;
}