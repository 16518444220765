.searchedLicenseCard {
  width: 100%;
  border: none;
  box-shadow: none;
  background-color: white;
}

.searchedLicenseCard .tile {
  width: 100%;
  height: min-content;
  display: inline-block;
  box-sizing: border-box;
  border: 1px solid rgb(182, 178, 178);
  box-shadow: none;
  border-radius: 8px;
  background-color: white;
  padding: 20px;
  resize: none;
  font-size: 16px;
  font-weight: 500;
  color: rgb(59, 56, 56);

  transition: box-shadow 0.10s;
}

.searchedLicenseCard .tile:hover {
  border: 1px solid black;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 3px;
}

.searchedLicenseCard .tile:active {
  background-color: rgb(235, 235, 235);
}

.searchedLicenseCard .tile[data-deadkey] {
  border-color: rgb(255, 211, 216);
  background-color: rgb(250, 228, 231);
  color: rgb(241, 54, 54);
}

.searchedLicenseCard .tile[data-deadkey]:hover {
  border: 1px solid red;
  box-shadow: rgba(255, 0, 0, 0.16) 0px 1px 4px, rgb(255, 51, 51) 0px 0px 0px 3px;
}

.searchedLicenseCard .tile[data-deadkey]:active {
  background-color: rgb(255, 213, 216);
}

.searchedLicenseCard .searchRow {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 2px;
}

.searchedLicenseCard .searchRow:first-child {
  font-size: 20px;
}

.searchedLicenseCard .value {
  color: rgb(6, 197, 70);
  margin-left: 4px;
}

.searchedLicenseCard .value[data-expired] {
  color: rgb(197, 6, 6);
  margin-left: 4px;
}