.activityChecker,
.activityChecker .graphAndScroll {
  width: 80%;
  min-width: 600px;
  margin: auto;
}

.activityChecker button.Mui-selected {
  padding: 8px 16px 8px 16px;
  background: #efefef;
  border-radius: 8px;
}

.activityChecker button {
  font-weight: bold;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-transform: capitalize;
  color: #6f767e;
}

.activityChecker .instructions {
  padding-top: 10px;
  text-align: center;
  font-weight: bold;
}

.activityChecker .selectionBar {
  margin-top: 40px;
  margin-bottom: 10px;
  position: relative;
}

.activityChecker .selectionBar:after {
  position: absolute;
  width: 100%;
  height: 1px;
  background: rgba(0, 0, 0, 0.12);
  content: "";
  top: 90px;
}

.activityChecker .activityTable {
  width: 100%;
  margin-top: 20px;
}

.activityChecker .activityTable td {
  padding: 4px;
}

.activityChecker .activityTable th {
  font-weight: bold;
  background-color: rgb(180, 180, 180);
}

.activityChecker .activityTable th:first-child {
  border-top-left-radius: 8px;
}

.activityChecker .activityTable th:last-child {
  border-top-right-radius: 8px;
  width: 1px;
  min-width: 12.5%;
  white-space: nowrap;
}

.activityChecker .activityTable tr:last-child>td:first-child {
  border-bottom-left-radius: 8px;
}

.activityChecker .activityTable tr:last-child>td:last-child {
  border-bottom-right-radius: 8px;
}

.activityChecker .activityTable td:last-child {
  width: 1px;
  min-width: 12.5%;
  white-space: nowrap;
}

.activityChecker .footer {
  position: fixed;
  background-color: white;
  border-top: solid black 1px;
  padding-top: 40px;
  padding-bottom: 40px;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
}

.activityChecker .activityWrapper {
  margin-top: 2em;
}

.activityChecker .activityWrapper .errorMessage {
  padding-top: 15px;
  text-align: center;
  font-size: 16pt;
}