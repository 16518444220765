body {
    /*Copied from Bootstrap v5.0*/
    font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.centeredError {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-top: 20px;
    font-size: 24pt;
    font-weight: bold;
}

.centeredLoading {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-top: 20px;
    font-size: 24pt;
}

#appBuildInfo {
    position: fixed;
    right: 20px;
    bottom: 20px;
    font-size: 1em;
    color: lightgray;
    font-weight: bold;
}