.sessionView {
    background-color: rgba(255, 255, 255, 0.75);
    height: 300px;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: -8px;
    margin-bottom: 3px;
    overflow-x: hidden;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.sessionView .message {
    text-align: center;
}

.sessionView .sessionUser {
    font-size: 0.8rem;
}

.sessionView .sessionTree .MuiTreeItem-content {
    padding-top: 0px;
    padding-bottom: 0px;
}
.sessionView .sessionTree .MuiTreeItem-label {
    line-height: normal;
}

.sessionView .sessionTree .sessionNode {
    font-size: 0.8rem;
    font-weight: bold;
}

.sessionView .sessionTree .treeLeaf {
    transform: scale(0.4,0.4);
}