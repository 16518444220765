.dashboard {
  margin-top: 40px;
  margin-left: 70px;
  margin-right: 70px;
}

.dashboard .searchBar {
  display: flex;
	border-radius: 12px;
	outline-color: black;
	background-color: #F4F4F4;
  margin-bottom: 30px;
	height: 40px;
	width: 360px;
}

.dashboard .searchBar :nth-child(2) {
  flex-grow: 1;
}

.dashboard .searchIcon {
  padding: 0px;
  width: 40px;
  height: 100%;
  display: flex;
  pointer-events: none;
}

.dashboard .searchIcon svg {
  margin: auto
}

.dashboard .clearSearchIcon {
  margin: auto
}

.dashboard .noResultsDiv {
  margin-top: 100px;
  font-size: 30px;
  width: 100%;
  font-weight: bold;
  display: flex;
  justify-content: center;
}

.dashboard .searchItems {
  width: 100%;
  max-width: 100%;
  margin-top: 10px;
  display: grid;
  gap: 0.4rem;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}

.dashboard .title {
  font-size: 22px;
  font-weight: 600;
  font-family: sans-serif;
  float: left;
}

.dashboard .sort {
  float: right;
  /* width: 160px; */
  white-space: nowrap;
  height: 35px;
  border: 1px solid rgb(182, 178, 178);
  margin-right: 15px;
  text-transform: none;
  color: black;
  background-color: #fff;
  border-radius: 5px;
}

.dashboard[data-searching] .widgetLayout{
  display: none;
}

.dashboardSortOptions .sortOptionsDrawer {
  width: 350px;
  max-width: 95%;
  padding: 20px;
  margin-left: 15px;
}

.sortOptionsDrawer .radio {
  font-weight: 470;
}

.sortOptionsDrawer input[type="radio"] {
  height: 15px;
  width: 15px;
  font-size: 16px;
}

.sortOptionsDrawer .sortBy {
  float: left;
  margin-top: 20px;
  border-radius: 2px;
  font-weight: 300px;
}

.dashboard .pageButtons {
  list-style: none;
  display: flex;
  justify-content: center;
  margin: auto;
  padding-top: 10px;
  margin-bottom: 20px;
}

.dashboard .pageButtons a {
  padding: 5px;
  margin: 4px;
  color: rgb(14, 7, 7);
  cursor: pointer;
  position: relative;
}

.dashboard .pageButtons a:hover {
  color: rgb(14, 12, 12);
  background-color: rgb(216, 213, 213);
}

.dashboard .pageButtons a:active {
  color: rgb(8, 8, 8);
  background-color: rgb(216, 213, 213);
}

.dashboard .pageButtons a:disabled {
  color: rgb(10, 9, 9);
  background-color: rgb(216, 213, 213);
}

.dashboard .resetButton {
  width: 150px;
  height: 35px;
  border: 1px solid rgb(182, 178, 178);
  text-transform: none;
  color: black;
  background-color: #fff;
  border-radius: 5px;
}

.dashboard .searchResults .filterText {
  font-weight: 600;
}

.dashboard .navButtons {
  float: right;
}

.dashboard .navButton {
  display: inline;
  white-space: nowrap;
}

.dashboard .navButton button {
  color: white;
  border-radius: 8px;
  text-transform: none;
  margin-left: 10px;
  height: 50px;
}

.dashboard .navButton.createButton button {
  background-color: #00007F;
}
.dashboard .navButton.createButton button:hover {
  background-color: #0000FF;
}

.dashboard .navButton.tempcodeButton button {
  background-color: #006F00;
}
.dashboard .navButton.tempcodeButton button:hover {
  background-color: #00AF00;
}

.dashboard .navButton.bulkButton button {
  background-color: #7F0000;
}
.dashboard .navButton.bulkButton button:hover {
  background-color: #FF0000;
}

.dashboard .navButton.reportButton button {
  background-color: #7F7F00;
  color:white;
}
.dashboard .navButton.reportButton button:hover {
  background-color: #FFFF00;
  color:black;
}

.dashboard .navButton span {
  opacity: 0%;
  width: 0px;
  max-width: 100%;
  overflow: hidden;
  transition: width 350ms, opacity 350ms;
}

.dashboard .navButton:hover span {
  opacity: 100%;
  width: 175px;
}