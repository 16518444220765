.dateScrollBar {
  position: relative;
  height: 1.5em;
  width: 100%;
}

.dateScrollBar .dateScrollTrack {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: lightgray;
  transform: scale(1, 0.25);
}

.dateScrollBar .dateScrollThumb {
  position: absolute;
  height: 100%;
  background-color: blue;
  border-radius: 3px;
  /*
  padding-left: 10px;
  padding-right: 10px;
  margin-left:-10px;
  margin-right:-10px;
  */
}

.dateScrollBar .dateScrollThumb:hover {
  background-color: lightblue;
}

.dateScrollBar .dateScrollThumb:active {
  background-color: darkblue;
}