.pageHeader > header {
  box-shadow: none;
  border-bottom: 1px solid #d8d8d8;
}

.pageHeader {
  flex-grow: 1;
}

.pageHeader .backIcon {
  cursor: pointer;
}

.pageHeader .headerToolbar {
  background-color: white;
  height: 96px;
}

.pageHeader div.headerTitle {
  color: black;
  flex-grow: 1;
  margin-left: 1rem;
  font-weight: bold;
}

.pageHeader .navBack {
  margin-top: -3px;
  z-index: 1;
}