.loginForm {
    height: 40px;
    width: 300px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    background: white;
    padding: 20px;
    margin-top: 300px;
}

.loginForm .siteTitle {
    text-align: center;
    font-size: 1.4rem;
    font-weight: bold;
}

.loginForm .errorStack {
    width: 100%;
    min-height: 40px;
}