.bulkUpdater {
    width: 600px;
    margin: auto;
}

.bulkUpdater .menuBar {
    margin-top: 10px;
    margin-bottom: 5px;
}

.bulkUpdater .menuButton{
    margin-top: 10px;
    margin-bottom: 5px;
    background-color: #00007F;
    color: white;
    border-radius: 8px;
    text-transform: none;
    height: 50px;
    width: 100%;
}

.bulkUpdater .menuButton:hover {
    background-color: #0000FF;
}

.bulkUpdater .menuButton.clearButton {
    background-color: #7F0000;
}
.bulkUpdater .menuButton.clearButton:hover {
    background-color: #FF0000;
}
.bulkUpdater .menuButton.runButton {
    color: black;
    background-color: #BFBF00;
}
.bulkUpdater .menuButton.runButton:hover {
    color: black;
    background-color: #FFFF00;
}

.bulkUpdater .rootModuleStack{
    font-size: 1.5rem;
    text-align: center;
    background-color: lightblue;
    border-radius: 6px;
    border: 1px solid black;
}

.bulkUpdater .lineClearButton {
    position: absolute;
    right: 0;
    top: 0;
}

.bulkUpdater .lineEditor {
    width:100%;
    margin-top:2px;
    margin-bottom:2px;
}

.bulkUpdater .lineEditor .MuiInputBase-root {
    background-color: white;
}
.bulkUpdater .lineEditor input {
    font-family: 'Courier New', Courier, monospace;
}

.bulkUpdater .whereStack {
    text-align: center;
    background-color: lightyellow;
    border-radius: 6px;
    border: 1px solid black;
}

.bulkUpdater .setStack {
    text-align: center;
    background-color: lightpink;
    border-radius: 6px;
    border: 1px solid black;
}

.bulkUpdater .previewWrapper {
    height: 50vh;
    max-width: 80vw;
    overflow: auto;
}

.bulkUpdatePreview .previewTab {
    text-transform: none;
}

.bulkUpdatePreview .previewTable {
    white-space: nowrap;
    border: 2px solid black;
    border-top: none;
    border-spacing: 0px;
    box-sizing: border-box;
}

.bulkUpdatePreview .previewTable thead{
    font-weight: bold;
}

.bulkUpdatePreview .previewTable th {
    position: sticky;
    background-color: white;
    border-top: 2px solid black;
    border-bottom: 2px solid black;
    top: 0;
    z-index: 10;
}

.bulkUpdatePreview .previewTable td {
    border: 1px solid gray;
    padding: 0px 6px;
}

.bulkUpdatePreview .previewTable .checkbox {
    padding: 0px;
}

.bulkUpdatePreview .previewTable tr:not([data-checked]) td {
    background-color: rgb(155,155,155);
}

.bulkUpdatePreview .previewTable td .newValue {
    font-weight: bold;
}

.bulkUpdatePreview .previewTable .updatedCell {
    background-color: rgb(255, 255, 100);
}

.bulkUpdatePreview .previewTable tr:not([data-checked]) td .newValue {
    color: gray;
    text-decoration: line-through;
}

.bulkUpdatePreview .previewTable span[data-null] {
    opacity: 80%;
    font-style: italic;
}