.tempcodeForm {
	width: 480px;
	max-width: 100%;
	margin-left: auto;
	margin-right: auto;
}

.tempcodeForm .result {
	text-align: center;
}
.tempcodeForm .result .tempcode {
	font-size: 1.5rem;
	font-weight: bold;
}

.tempcodeForm .tempcodeItem {
    margin-top: 1.5rem;
    width: 100%;
}

.tempcodeForm .genButton {
	color: #DDDDDD;
	background-color: #006F00;
}

.tempcodeForm .genButton:hover {
	color: #FFFFFF;
	background-color: #00AF00;
}

.tempcodeForm .copyButton {
	margin-left: auto;
}

.tempcodeForm .MuiButton-outlined {
	height: 3rem;
	font-weight: 550;
	margin-top: 1rem;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	text-transform: none;
	background-color: #006F00;
	color: black;
	box-shadow: none;
}

.tempcodeForm .MuiButton-outlined:hover {
    background-color: #00AF00;
	box-shadow: none;
}
