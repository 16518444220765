.licenseEditor {
	max-width: 1000px;
	padding-left: max(0px,min(180px,16% - 120px));
}

.licenseEditor .MuiDivider-root {
	padding-top: 25px;
}

.licenseEditor .MuiTypography-h5 {
	padding-top: 20px;
	padding-bottom: 10px;
}

.licenseEditor .scope[data-access~="read"]:not([data-access~="write"]) input:disabled {
  color: rgba(0, 0, 0, 1);
  -webkit-text-fill-color: rgba(0, 0, 0, 1);
}

.licenseEditor .customerInfo {
  font-style: italic;
  font-weight: bold;
  font-size: 10pt;
}

.licenseEditor .searchCustomerName {
  font-size: 10pt;
  width: 100%;
}

.licenseEditor .searchCustomerEmail {
  font-size: 8pt;
  font-style: italic;
  width: 100%;
}

.licenseEditor .saveCreate {
  position: absolute;
  float: right;
  height: 40px;
  margin-top: 5px;
  font-weight: 600;
  line-height: 42px;
}

.licenseEditor .editorButton {
		width: 100%;
		font-weight: 550;
		overflow: hidden;
		white-space: nowrap;
		text-transform: none;
}
.licenseEditor .editorButton.MuiButton-root {
  border: 1px #D0D0D0 solid;
  color: black;
}

.licenseEditor .licenseNoWrapper .MuiFormControl-root{
	width: calc(100% - 42px);
}

.licenseEditor button.autoGenerateButton {
	color: black;
	border-color: black;
	height: 40px;
	width: 40px;
	padding: 7px;
	min-width: 0;
	float: right;
}
.licenseEditor button.autoGenerateButton:hover {
  border-color: black;
}

.licenseEditor .autoGenerateSpinner {
  height: 24px;
}

.licenseEditor .licencesSave {
  margin-top: 30px;
  margin-inline-start: 70px;
  margin-right: 65px;
  margin-left: -50px;
}

.licenseEditor .submitArrow {
  float: right;
  margin-top: -60px;
}

.licenseEditor .arrowIcons {
  margin-right: 5px;
}

.licenseEditor .userDetails {
  display: none;
}

.licenseEditor .minuteBalanceLiveIcon {
  color: red;
}

.licenseEditor .minuteBalanceLiveIcon[data-report] {
  color: blue;
}

.licenseEditor .minuteBalanceAddIcon {
  color: blue;
}

.licenseEditor .iconButton {
  background-color: transparent;
  border: none;
}

.licenseEditor .addIcon {
  margin-left: -5px;
  margin-top: 15px;
  cursor: pointer;
}

/*****************/

.reportTable {
  white-space: nowrap;
  border: 1px solid black;
}

.reportTable th{
  border: 1px solid black;
  top: 0;
  z-index: 10;
}

.reportTable td {
  padding: 0px 6px;
  border: 1px solid lightgray;
  text-align: right;
}

.licenseEditor:not([data-create]) .minuteBalance input {
  font-weight: bold;
  -webkit-text-fill-color: red;
}

.licenseEditor:not([data-create]) .minuteBalance input[value^="+"] {
  -webkit-text-fill-color: limegreen;
}

/*starts with "N" matches both "No balance" and "Not adjusted"*/
.licenseEditor:not([data-create]) .minuteBalance input[value^="N"] {
  font-weight: normal;
  -webkit-text-fill-color: black;
}
