.autoreplenishReport {
  margin: auto;
  max-width: 800px;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}

.autoreplenishReport button.retryButton {
  text-transform: none;
  background-color: lightblue;
  width: 10rem;
  height: 2.5rem;
}

.autoreplenishReport button.executeButton {
  text-transform: none;
  background-color: red;
  color: black;
  width: 20rem;
  max-width: 95%;
  height: 2.5rem;
}

.autoreplenishReport button.downloadButton {
  text-transform: none;
  background-color: lime;
  color: black;
  width: 20rem;
  max-width: 95%;
  height: 2.5rem;
}